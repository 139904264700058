@use '../config/' as *;

.waf-squad {
    @extend %white-bg;
    @extend %p-x-half;
    .waf-head {
        aspect-ratio: 2/3;
        @extend %relative;
        @extend %flex-n-fe;
        @extend %p-y-two-space;
        @extend %p-x-one-n-half;
        @extend %m-x-full-neg;
        &::before {
            content: '';
            z-index: var(--zindexhalloffamecontent);
            width: var(--window-inner-width);
            background: url('/static-assets/images/cssimages/hall-of-fam-mob.png?v=#{$image-version}') center / cover no-repeat;
            @extend %h-100;
            @extend %position-t-l;
        }
        .head-wrap {
            flex-direction: column;
            align-items: flex-start;
        }
        .head-tab {
            @extend %d-none;
        }
        .title {
            line-height: 4.4rem;
            @extend %m-y-zero;
            @extend %uppercase;
            @extend %c-white-10;
            @extend %font-42-sb;
        }
        .desc {
            @extend %c-white-10;
            @extend %font-14-pr;
        }
    }
    .filter-section {
        padding-inline: 0;
        .filter-search {
            @extend %d-none;
        }
    }
    .accordion {
        &-item {
            border: .1rem solid var(--c-blue-light);
            box-shadow: 0 .4rem 1.6rem 0 rgba(78, 78, 78, 0.10);
            @extend %half-radius;
            @extend %p-x-zero;
        }
        &-header {
            height: 4.8rem;
            @extend %p-x-half;
            @include border-radius(var(--half-radius) var(--half-radius) 0 0);
            &[aria-expanded="true"] {
                @extend %c-blue-bg-1;
            }
            &::after {
                width: 2.4rem;
                height: 2.4rem;
                @extend %flex-c-c;
                @extend %circle-radius;
                @extend %c-white-bg-10;
                @extend %c-blue-10;
            }
            .btn-text {
                @extend %capitalize;
                @extend %c-blue-10;
                @extend %font-14-psb;
            }
        }
    }
    .card {
        &-list {
            overflow: hidden;
        }
        &-item {
            @extend %relative;
            @extend %p-full;
            @extend %flex-n-c;
            &:not(:last-child) {
                border-bottom: .1rem solid var(--c-blue-light);
            }
        }
        &-thumbnail {
            width: 7rem;
            height: 7rem;
            @extend %circle-radius;
            img {
                object-fit: contain;
                @extend %w-100;
                @extend %h-100;
            }
        }
        &-content {
            @extend %p-l-full;
        }
        &-title {
            @extend %flex-column;
            @extend %m-y-zero;
            .name {
                @extend %capitalize;
            }
            .fname {
                @extend %c-blue-1-10;
                @extend %font-14-pr;
            }
            .lname {
                @extend %c-blue-10;
                @extend %font-16-pr;
            }
        }
        &-label {
            @extend %flex-n-c;
            img {
                width: 1.2rem;
                height: 1.2rem;
            }
            .text {
                @extend %p-l-half;
                @extend %capitalize;
                @extend %c-blue-10;
                @extend %font-12-pr;
            }
        }
        &-action {
            .btn {
                font-size: 0;
                cursor: pointer;
                @extend %h-100;
                @extend %w-100;
                @extend %position-t-l;
            }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .waf-squad {
        @include white-bg();
        .waf-head {
            height: 27rem;
            aspect-ratio: unset;
            @include flex-config(flex,null,null,center);
            &::before {
                width: calc(var(--window-inner-width) + (var(--container-white-space)/2));
                left: calc(var(--container-white-space)*-1);
                background: url('/static-assets/images/cssimages/hall-of-fam.png?v=#{$image-version}') center / cover no-repeat;
            }
            .head-wrap {
                width: 50%;
            }
            .desc {
                font-size: 1.6rem;
            }
            .title {
                font-size: 4.4rem;
                line-height: 4.2rem;
            }
        }
        .accordion {
            &-header {
                height: 7.2rem;
                padding-inline: var(--full-space);
                .btn-text {
                    font-size: 2rem;
                }
            }
        }
        .card {
            &-list {
                flex-wrap: wrap;
                padding-inline: calc(2*var(--full-space));
                @include flex-config(flex,null,flex-start,center);
            }
            &-item {
                padding: var(--two-space) 0;
                flex-basis: 33.33%;
                flex-direction: column;
                align-items: center;
                &:not(:last-child) {
                    border-bottom: 0;
                }
            }
            &-thumbnail {
                width: 11rem;
                height: 11rem;
            }
            &-content {
                padding-top: var(--half-space);
                padding-left: 0;
            }
            &-title {
                align-items: center;
                .fname {
                    font-size: 1.6rem;
                }
                .lname {
                    font-size: 1.8rem;
                }
            }
            &-label {
                justify-content: center;
                padding-top: var(--half-space);
                img {
                    width: 1.6rem;
                    height: 1.6rem;
                }
                .text {
                    font-size: 1.4rem;
                }
            }
        }
        .filter-section {
            margin-top: var(--two-space);
        }
    }
}
@media (min-width:$desktop-min-width) {
    .waf-squad {
        .waf-head {
            .title {
                font-size: 6.4rem;
                line-height: 6rem;
            }
            .desc {
                font-size: 1.8rem;
            } 
        }
        .card {
            &-list {
                padding-inline: calc(5*var(--full-space));
            }
            &-item {
                flex-basis: 20%;
            }
        }
    }
}